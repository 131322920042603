import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UsersListResponseType, UserType } from 'shared/types/auth';
import { request, RequestMethodType } from 'shared/utils/request';

import { useToast } from 'shared/hooks/use-toast';
export const useUsers = (filters?: { limit?: number; page?: number }) => {
  return useQuery<UsersListResponseType>(
    ['users', filters],
    () => {
      const { limit, page } = filters || {};
      let queryParams = 'sortBy=createdAt:desc';
      if (limit) {
        queryParams += `&limit=${limit}`;
      }
      if (page) {
        queryParams += `&page=${page}`;
      }
      return request(
        `/users?${queryParams}`,
        RequestMethodType.GET,
        null,
        true,
      );
    },
    { keepPreviousData: true, staleTime: 5000 },
  );
};

export const useUpdateUser = (id: string) => {
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (user: Partial<UserType>) =>
      request(`/users/${id}`, RequestMethodType.PATCH, user, true),
    {
      onError: (error: AxiosError) => {
        addToast(`Failed to update user profile`, {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        addToast(`User profile updated`);
      },
    },
  );
};
