/**
 * Asynchronously loads the component for Reports
 */

import Loader from 'shared/ui/components/loader';
import { lazyLoad } from 'shared/utils/loadable';

export const Instructions = lazyLoad(
  () => import('./index'),
  (module) => module.Instructions,
  {
    fallback: <Loader />,
  },
);
