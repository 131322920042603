import { HealthTests } from './../pages/health-tests/loadable';
import { Reports } from 'pages/reports/loadable';
import { CreateFoodEntry } from 'pages/create-food-entry/loadable';
import { Dashboard } from 'pages/home/loadable';
import { Home } from 'pages/dashboard/loadable';
import { Login } from 'pages/login/loadable';
import { Register } from 'pages/register/loadable';
import { NotFound } from './NotFound';
import { Users } from 'pages/users';
import { CreatePrescription } from 'pages/create-prescription/loadable';
import { Medicines } from 'pages/medicines/loadable';
import { Instructions } from 'pages/instructions/loadable';
import { ViewPrescription } from 'pages/view-prescription/loadable';
import { UpdateUser } from 'pages/user/loadable';
import { UpsertLocation } from 'pages/location/loadable';
import { Locations } from 'pages/locations/loadable';

export const openRoutes = [
  {
    path: '/',
    Component: Dashboard,
  },
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/register',
    Component: Register,
  },
  {
    path: '*',
    Component: NotFound,
  },
];

export const viewerRoutes = [
  {
    path: '/',
    Component: Home,
  },

  {
    path: '/medicines',
    Component: Medicines,
  },
  {
    path: '/tests',
    Component: HealthTests,
  },

  {
    path: '/instructions',
    Component: Instructions,
  },
  {
    path: '/prescription/:prescriptionId/view',
    Component: ViewPrescription,
  },
];
export const customerRoutes = [
  {
    path: '/food-entry/create',
    Component: CreateFoodEntry,
  },
  {
    path: '/food-entry/:entryId/edit',
    Component: CreateFoodEntry,
  },
  {
    path: '/prescription/create',
    Component: CreatePrescription,
  },
  {
    path: '/prescription/:prescriptionId/edit',
    Component: CreatePrescription,
  },
  {
    path: '*',
    Component: NotFound,
  },
  ...viewerRoutes,
];

export const adminRoutes = [
  {
    path: '/reports',
    Component: Reports,
  },
  {
    path: '/users',
    Component: Users,
  },

  {
    path: '/users/:userId/edit',
    Component: UpdateUser,
  },

  {
    path: '/locations',
    Component: Locations,
  },

  {
    path: '/location/:locationId/edit',
    Component: UpsertLocation,
  },

  {
    path: '/location/create',
    Component: UpsertLocation,
  },
  ...customerRoutes,
];
