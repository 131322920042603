import { Fragment } from 'react';

import { Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import { UserType } from 'shared/types/auth';
import Loader from 'shared/ui/components/loader';
import { TableCell, TableRow } from 'shared/ui/elements/table';
import { useUpdateUser } from '../service/users.hooks';

interface Props {
  users: UserType[];
  dataLoading: boolean;
}
// const RoleEnum = {
//   admin: 'Admin',
//   user: 'Doctor',
//   viewer: 'Viewer/Compounder',
// };
enum RoleEnum {
  admin = 'Admin',
  user = 'Doctor',
  viewer = 'Viewer/Compounder',
}
const UserRow = ({ user }: { user: UserType }) => {
  const { mutate, isLoading } = useUpdateUser(user._id);
  const navigate = useNavigate();

  return (
    <TableRow key={user._id}>
      <TableCell component="th" scope="row">
        {user.name}
      </TableCell>
      <TableCell align="right">{user.email}</TableCell>
      {/* <TableCell align="right">{user.designation}</TableCell> */}
      {/* <TableCell align="right">{user.graduation}</TableCell>
      <TableCell align="right">{user.title}</TableCell>
      <TableCell align="right">{user.postGraduation}</TableCell> */}
      {/* <TableCell align="right">{user.additionalInfo}</TableCell> */}
      <TableCell align="right">{user.role && RoleEnum[user.role]}</TableCell>
      <TableCell align="right">{user.defaultLocation?.name}</TableCell>

      <TableCell align="right">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            aria-label="edit"
            size="small"
            sx={{
              color: 'secondary.dark',
            }}
            onClick={() => navigate(`/users/${user._id}/edit`)}
          >
            <Edit fontSize="small" />
          </IconButton>
          <Switch
            checked={user.isProfileApproved}
            onChange={() =>
              mutate({
                isProfileApproved: !user.isProfileApproved,
              })
            }
            disabled={isLoading}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};
export default function UsersTableBody({ users, dataLoading }: Props) {
  if (dataLoading) {
    return <Loader />;
  }
  if (users.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={4}>No users found</TableCell>
      </TableRow>
    );
  }
  return (
    <Fragment>
      {users?.map((user: UserType) => (
        <UserRow key={user._id} user={user} />
      ))}
    </Fragment>
  );
}
